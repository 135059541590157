.instruction-dashboard {
  position: relative;
  h2 {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }

  .parameters {
    flex: 1.5;
    padding: 24px;
  }
  .objects {
    flex: 5;
    background-color: var(--color-near-white);
  }
  .overview {
    flex: 2;
    padding: 24px;

    .title {
      font-size: 24px;
      font-family: Avenir;
      font-weight: 900;
    }
  }
}